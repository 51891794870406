<template>
  <div class="tw-min-h-screen tw-flex tw-flex-col tw-px-4 tw-pb-4">
    <ClientOnly>
      <!-- 专门用于 TreasureBox -->
      <div class="tw-flex tw-justify-between tw-items-start tw-pt-7">
        <div class="tw-flex-1 tw-flex">
          <div class="lg:tw-hidden">
            <slot name="leftTop" v-if="$slots.leftTop"></slot>
            <h1
              v-else
              class="tw-flex tw-items-center tw-cursor-pointer tw-text-neutral/40"
              @click="goBack"
            >
              <SvgoBack class="tw-mr-1" /> Back
            </h1>
          </div>
          <svgo-logo class="tw-fill-neutral tw-h-10 tw-hidden lg:tw-block" />
        </div>

        <!-- <slot name="middleTop" v-if="$slots.middleTop"></slot> -->
        <div class="tw-w-full tw-max-w-lg tw-mx-auto tw-hidden xl:tw-block">
          <AppMenus />
          <div class="tw-hidden xl:tw-flex tw-items-end tw-h-14">
            <!-- <h1
              v-if="!$slots.leftTop"
              class="tw-flex tw-items-center tw-cursor-pointer tw-text-neutral/40"
              @click="goBack"
            >
              <SvgoBack class="tw-mr-1" /> Back
            </h1> -->
          </div>
        </div>
        <div class="tw-flex-1 tw-flex tw-items-center tw-justify-end">
          <slot name="rightTop" v-if="$slots.rightTop"></slot>
          <NuxtLink v-else to="/me"><SvgoUserIcon /></NuxtLink>
        </div>
      </div>

      <div
        class="tw-max-w-lg tw-mx-auto tw-flex-1 tw-flex tw-flex-col tw-w-full"
      >
        <div class="tw-py-4 tw-min-h-2">
          <slot name="tip"></slot>
        </div>
        <div class="tw-flex-1 tw-flex tw-flex-col tw-w-full tw-h-full">
          <slot />
        </div>
      </div>
    </ClientOnly>
    <RegisteModal />
  </div>
</template>
<script setup lang="ts">
const route = useRoute();
const router = useRouter();
const goBack = () => {
  if (route.query && route.query.backUrl) {
    router.push(route.query.backUrl as string);
  } else {
    router.back();
  }
};
</script>
