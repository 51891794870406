<template>
  <div
    class="tw-flex tw-items-center tw-px-2 tw-py-2 tw-bg-white tw-rounded-[40px] tw-space-x-1 tw-shadow"
  >
    <button
      @click="navigateTo('/home')"
      class="tw-flex-1 tw-btn tw-rounded-full tw-btn-2xl tw-fill-neutral/[.21] hover:tw-fill-base-100"
      :class="
        isHomeActive
          ? 'tw-btn-active tw-btn-error tw-fill-base-100'
          : 'tw-btn-ghost'
      "
    >
      <div>
        <svgo-home-inactive
          :class="{ 'tw-fill-base-100': isHomeActive }"
          class="tw-mx-auto"
        />
        <p
          class="tw-text-xs tw-font-semibold tw-text-neutral/[.21] tw-leading-relaxed"
          :class="{ 'tw-hidden': isHomeActive }"
        >
          HOME
        </p>
      </div>
    </button>
    <button
      class="tw-flex-1 tw-btn tw-rounded-full tw-btn-2xl tw-fill-neutral/[.21] hover:tw-fill-base-100"
      :class="isMenuActive ? 'tw-btn-active tw-btn-error' : 'tw-btn-ghost'"
      @click="dialog.open()"
    >
      <div>
        <svgo-trade-inactive
          :class="{ 'tw-fill-base-100': isMenuActive }"
          class="tw-mx-auto"
        />
        <p
          class="tw-text-xs tw-font-semibold tw-text-neutral/[.21] tw-leading-relaxed"
          :class="{ 'tw-hidden': isMenuActive }"
        >
          TRADE
        </p>
      </div>
    </button>
    <button
      @click="navigateTo('/wallet')"
      class="tw-flex-1 tw-btn tw-rounded-full tw-btn-2xl tw-fill-neutral/[.21] hover:tw-fill-base-100"
      :class="
        isWalletActive
          ? 'tw-btn-active tw-btn-error tw-fill-base-100'
          : 'tw-btn-ghost'
      "
    >
      <div>
        <svgo-wallet-inactive
          :class="{ 'tw-fill-base-100': isWalletActive }"
          class="tw-mx-auto"
        />
        <p
          class="tw-text-xs tw-font-semibold tw-text-neutral/[.21] tw-leading-relaxed"
          :class="{ 'tw-hidden': isWalletActive }"
        >
          WALLET
        </p>
      </div>
    </button>
    <UIDialog
      ref="dialog"
      class="tw-modal-bottom tw-px-4 tw-pb-28"
      maskClosable
      closable
      v-model:visible="visible"
    >
      <div class="tw-px-2">
        <template v-for="(item, i) in menus" :key="item.title">
          <div
            class="tw-flex tw-flex-col tw-space-y-1 tw-px-4 tw-cursor-pointer"
            @click="navigateTo(item.path)"
          >
            <h2
              class="tw-text-xl tw-text-neutral/70 tw-leading-none tw-font-semibold"
            >
              {{ item.title }}
            </h2>
            <p class="tw-text-xs tw-text-neutral/40 tw-leading-relaxed">
              {{ item.desc }}
            </p>
          </div>
          <div class="tw-divider tw-my-1" v-if="i < menus.length - 1"></div>
        </template>
      </div>
    </UIDialog>
  </div>
</template>
<script setup lang="ts">
const router = useRouter();
const route = useRoute();
const { goToBeforeCheckKyc } = useUserInfo();
const dialog = ref();
const visible = ref(false);

const menus = [
  { title: "Buy", desc: "Buy crypto with cash", path: "/trade/buy" },
  { title: "Sell", desc: "Sell crypto for cash", path: "/trade/sell" },
  {
    title: "Receive",
    desc: "Transform crypto from another wallet",
    path: "/wallet/crypto/receive",
  },
  {
    title: "Send",
    desc: "Transfer crypto to another wallet",
    path: "/wallet/crypto/send",
  },
  {
    title: "Top Up",
    desc: "Transfer cash from your bank",
    path: "/wallet/cash/topup",
  },
  {
    title: "Cash Out",
    desc: "Transfer cash to your cank",
    path: "/wallet/cash/cashout",
  },
];

const isHomeActive = computed(() => route.path === "/home" && !visible.value);
const isWalletActive = computed(
  () => route.path === "/wallet" && !visible.value
);
const isMenuActive = computed(
  () => visible.value || (!isHomeActive.value && !isWalletActive.value)
);
const navigateTo = (path: string) => {
  goToBeforeCheckKyc(() => {
    dialog.value.close();
    router.push({
      path: path,
    });
  });
};
</script>
